import { template as template_1cc93b277b154c769f8ecde6cf9bb293 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_1cc93b277b154c769f8ecde6cf9bb293(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
