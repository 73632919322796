import { template as template_da2efdd86b9145f59490517889dbea1d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_da2efdd86b9145f59490517889dbea1d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
