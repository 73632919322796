import { template as template_c2bbb3b4b77341c7bbc609c879893840 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c2bbb3b4b77341c7bbc609c879893840(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
