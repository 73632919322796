import { template as template_f482f31f16f94bd0b6f67d342e9a14d6 } from "@ember/template-compiler";
const FKLabel = template_f482f31f16f94bd0b6f67d342e9a14d6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
